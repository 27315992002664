import { useMemo, useState } from 'react'

import PATextFilter from './PATextFilter'

const usePAFilters = (originalColumns: Array) => {
  const [visibilities, setVisibilities] = useState({})

  const setFilterVisibility = (field: string, visible: boolean) => {
    setVisibilities({ ...visibilities, [field]: visible })
  }

  const columns = useMemo(() => originalColumns.map(originalColumn => {
    const column = { ...originalColumn }
    if (column.customFilter) {
      if (column.customFilter === 'text') {
        column.filterDropdown = params => PATextFilter({ ...params, visible: visibilities[column.dataIndex] })
        column.onFilterDropdownVisibleChange = visible => setFilterVisibility(column.dataIndex, visible)
      }
    }
    return column
  }), [originalColumns, visibilities])

  return columns
}

export default usePAFilters
