import React from 'react'

const Signin = React.lazy(() => import('./Signin'))
const Welcome = React.lazy(() => import('./Welcome'))

export const authPagesConfig = [
  {
    path: '/signin',
    element: <Signin />
  },
  {
    path: '/welcome',
    element: <Welcome />
  }
]
