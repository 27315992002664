import React from 'react'
import { Layout } from 'antd'

import AppContentView from '../../AppContentView'
import AppScrollbar from '../../AppScrollbar'
import AppAllLogo from '../components/AppAllLogo'
import AppSidebar from './AppSidebar'

import './index.style.less'

const Default = () => {
  return (
    <Layout className='app-layout'>
      <div className='logo'>
        <AppAllLogo />
      </div>
      <AppSidebar />
      <Layout className='app-layout-main'>
        <AppScrollbar className='main-scrollbar'>
          <AppContentView />
        </AppScrollbar>
      </Layout>
    </Layout>
  )
}

export default React.memo(Default)
