import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaPause, FaPlay } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import ReactPlayer from 'react-player'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import { PointDialog } from '../../components'
import { useProgressService, useQuestionsList } from '../../services'
import { StringUtils } from '../../utils'

const LectureVideo = ({ delta, lecture }) => {
  if (!(lecture?.content?.fileType === 'video')) return ''

  const player = useRef()
  const [initiated, setInitiated] = useState(false)
  const [seeked, setSeeked] = useState(false)
  const [lastBookmark, setLastBookmark] = useState(0)
  const [isPlaying, setPlaying] = useState(false)
  const [point, setPoint] = useState()
  const [dialogVisibility, setDialogVisibility] = useState(false)

  const navigate = useNavigate()
  const t = StringUtils.useT()

  const { data } = useQuestionsList({
    filters: { lecture_id: lecture.id },
    sorter: [{ field: 'order', type: 'asc' }]
  })
  const hasQuestion = data?.data?.length > 0

  const togglePlay = () => setPlaying(!isPlaying)

  const { store, storeCompletion, storeView } = useProgressService()
  const updateProgress = useCallback(async () => {
    if (!initiated) return false
    try {
      const bookmark = player.current.getCurrentTime() / player.current.getDuration()
      if (bookmark === lastBookmark) return false
      if (bookmark < 0.9999) {
        await store({ lectureId: lecture.id, bookmark })
      }
      setLastBookmark(bookmark)
    } catch (error) { }
  }, [initiated, lecture, lastBookmark])

  const handleVideoEnded = useCallback(async () => {
    if (!point) {
      const newPoint = await storeView(lecture.id)
      setPoint(newPoint)
      if (!hasQuestion) {
        await storeCompletion(lecture.id)
      }
    }
    setDialogVisibility(true)
  }, [lecture, point])

  const handleContinue = useCallback(() => {
    if (hasQuestion) {
      navigate(`/course/${lecture.courseId}/lecture/${lecture.id}/quiz`)
    } else {
      navigate('/course')
    }
  }, [hasQuestion])

  const continueLabel = useMemo(() => {
    if (hasQuestion) {
      return t('lecture.actions.takeQuiz')
    }
    return t('home')
  }, [hasQuestion])

  useEffect(() => {
    const intervalId = setInterval(updateProgress, 5000)
    return () => clearInterval(intervalId)
  }, [updateProgress])

  useEffect(() => {
    if (!initiated || !lecture.progress?.bookmark) return false
    if (!seeked) {
      player.current.seekTo(lecture.progress.bookmark * player.current.getDuration())
      setSeeked(true)
    }
  }, [initiated, seeked, lecture])

  return (
    <>
      <ReactPlayer
        ref={player}
        url={lecture.content.url}
        playIcon={<span />}
        playing={isPlaying}
        width='100%'
        height={`calc(100% - ${delta[0]}px)`}
        config={{ file: { attributes: { style: { width: '100%', maxHeight: `calc(100vh - ${delta[1]}px)` } } } }}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        onReady={() => setInitiated(true)}
        onEnded={handleVideoEnded}
        controls
      />
      <Button onClick={togglePlay} type='primary' className={`btn-play ${isPlaying ? 'btn-pause' : ''}`}>
        {isPlaying ? <FaPause /> : <FaPlay />}
      </Button>
      <PointDialog
        continueLabel={continueLabel}
        onCancel={() => setDialogVisibility(false)}
        onContinue={handleContinue}
        visible={dialogVisibility}
      >
        <div className='emoji mb20'>👏</div>
        <div className='text f30 mb40'>{t('lecture.hints.viewed', { lecture: lecture.title })}</div>
        <div className='text f30'>{t('lecture.hints.pointsEarned.intro.short')}</div>
        <div className='text f40 mb30'>{t('lecture.hints.pointsEarned', { n: point?.points || 0 })}</div>
        {hasQuestion && (<div className='text f30'>{t('lecture.hints.takeQuiz')}</div>)}
      </PointDialog>
    </>
  )
}

LectureVideo.propTypes = {
  delta: PropTypes.arrayOf(PropTypes.number),
  lecture: PropTypes.any
}

export { LectureVideo }
