import React from 'react'
import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'

import { ScreenUtils, StringUtils } from '../../../utils'

import './index.style.less'

const PointDialog = ({
  children,
  continueLabel,
  onCancel,
  onContinue,
  ...props
}) => {
  const { refactor } = ScreenUtils.useWindowDimensions()
  const t = StringUtils.useT()

  return (
    <Modal {...props} width={refactor(840, 1366)} closable={false} footer={null} className='point-dialog-container'>
      {children}
      <div className='actions d-flex justify-content-between'>
        <Button onClick={onCancel} className='ant-btn-danger'>{t('app.actions.close')}</Button>
        {continueLabel && (
          <Button onClick={onContinue} type='primary'>{continueLabel}</Button>
        )}
      </div>
    </Modal>
  )
}

PointDialog.propTypes = {
  children: PropTypes.any,
  continueLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func
}

export default PointDialog
