import type { Config } from '../models'

export const calcLevel = (points: number, config: Config) => {
  if (points >= config.lvl2) return 2
  if (points >= config.lvl1) return 1
  if (points >= config.lvl0) return 0
  return -1
}

export const calcPointsNeeded = (points: number, config: Config) => {
  if (points < config.lvl0) return config.lvl0 - points
  if (points < config.lvl1) return config.lvl1 - points
  if (points < config.lvl2) return config.lvl2 - points
  return 0
}

export const calcLevelPercentage = (points: number, config: Config) => {
  if (points < config.lvl0) return 0
  if (points < config.lvl1) return (points - config.lvl0) * 50 / (config.lvl1 - config.lvl0)
  if (points < config.lvl2) return 50 + ((points - config.lvl1) * 50 / (config.lvl2 - config.lvl1))
  return 100
}
