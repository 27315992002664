import { useQuery, useQueryClient } from 'react-query'

import type { Question } from '../../models'
import type { ListParams } from '../generic'
import { QuestionService } from './service'
import type { QuestionsListResult } from './service'

export const QN_QUESTIONS_LIST = 'questions-list'
export const QN_QUESTION_DETAIL = 'question-detail'

export const useQuestionsList = (params?: ListParams) => {
  const service = new QuestionService(params)
  return useQuery<QuestionsListResult | undefined, Error>(
    [QN_QUESTIONS_LIST, params],
    () => service.fetchList(),
    {
      staleTime: 300000
    }
  )
}

export const useQuestion = (id: string) => {
  const service = new QuestionService()
  return useQuery<Question | undefined, Error>(
    [QN_QUESTION_DETAIL, id],
    () => service.fetchById(id),
    {
      staleTime: 300000,
      retry: 1
    }
  )
}

export const useQuestionService = () => {
  const service = new QuestionService()
  const queryClient = useQueryClient()

  const store = async (question: Question) : Promise<Question | undefined> => {
    const newQuestion = await service.store(question)
    await queryClient.invalidateQueries(QN_QUESTIONS_LIST)
    return newQuestion
  }

  const update = async (question: Question) : Promise<Question | undefined> => {
    const updatedQuestion = await service.update(question)
    setTimeout(async () => {
      await queryClient.invalidateQueries([QN_QUESTION_DETAIL, question.id])
    }, 500)
    await queryClient.invalidateQueries(QN_QUESTIONS_LIST)
    return updatedQuestion
  }

  const storeOrUpdate = async (question: Question) : Promise<Question | undefined> => {
    return question.id ? update(question) : store(question)
  }

  const updateOrder = async (courseId: string, ids: string[]) => {
    await service.updateOrder(courseId, ids)
    queryClient.invalidateQueries([
      QN_QUESTIONS_LIST,
      {
        filters: { course_id: courseId },
        sorter: [{ field: 'order', type: 'asc' }]
      }
    ])
  }

  const remove = async (id: string) => {
    await service.remove(id)
    queryClient.invalidateQueries(QN_QUESTIONS_LIST)
  }

  return { store, update, storeOrUpdate, updateOrder, remove }
}
