import React, { useEffect, useRef } from 'react'
import { Button, Input } from 'antd'
import PropTypes from 'prop-types'

import { StringUtils } from '../../../../utils'

const PATextFilter = ({
  clearFilters,
  confirm,
  selectedKeys,
  setSelectedKeys,
  visible
}) => {
  const inputRef = useRef()
  const t = StringUtils.useT()

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        inputRef.current.focus()
      })
    }
  }, [visible])

  const confirmAndClose = event => {
    event.stopPropagation()
    confirm({ closeDropdown: true })
  }

  const clearAndConfirm = () => {
    clearFilters()
    confirm({ closeDropdown: false })
    setTimeout(() => {
      inputRef.current.focus()
    })
  }

  return (
    <div className='custom-filter-dropdown'>
      <Input
        ref={inputRef}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirmAndClose}
      />
      <Button type='primary' onClick={confirmAndClose}>
        {t('app.actions.search')}
      </Button>
      <Button onClick={clearAndConfirm}>
        {t('app.actions.reset')}
      </Button>
    </div>
  )
}

PATextFilter.propTypes = {
  clearFilters: PropTypes.func,
  confirm: PropTypes.func,
  selectedKeys: PropTypes.array,
  setSelectedKeys: PropTypes.func,
  visible: PropTypes.bool
}

PATextFilter.defaultProps = {
  visible: false
}

export default PATextFilter
