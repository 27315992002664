import { useIntl } from 'react-intl'

export const truncate = (input: string, maxLength = 20) => input.length > maxLength ? `${input.substring(0, maxLength)}...` : input

export const useT = () => {
  const intl = useIntl()
  const t = (id: string, values?: Record<string, string>) => {
    try {
      return intl.formatMessage({ id }, values)
    } catch (error) {
      if (id.startsWith('server.')) {
        return id.substring(7)
      }
      return id
    }
  }
  return t
}
