import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { useAuthService } from '../../../../services'
import { StringUtils } from '../../../../utils'
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS
} from '../../../../shared/constants/ActionTypes'
import { setAuthToken } from './jwt-api'

const JWTAuthContext = createContext()
const JWTAuthActionsContext = createContext()

export const useJWTAuth = () => useContext(JWTAuthContext)

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext)

const JWTAuthAuthProvider = ({ children }) => {
  const [jwtAuthData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true
  })
  const [lastUserId, setLastUserId] = useState(0)

  const dispatch = useDispatch()
  const { login, fetchInfo, logout: logoutAPI } = useAuthService()
  const t = StringUtils.useT()

  const logout = async () => {
    logoutAPI()
    localStorage.removeItem('kiosk-token')
    setAuthToken()
    setJWTAuthData({ user: null, isLoading: false, isAuthenticated: false })
  }

  useEffect(() => {
    const getAuthUser = async () => {
      const token = localStorage.getItem('kiosk-token')

      try {
        if (!token) throw new Error('no token')
        setAuthToken(token, logout)
        const user = await fetchInfo()
        setJWTAuthData({ user, isLoading: false, isAuthenticated: true })
        setLastUserId(user.id)
      } catch (error) {
        setJWTAuthData({ user: undefined, isLoading: false, isAuthenticated: false })
      }
    }

    getAuthUser()
  }, [])

  const signInUser = async ({ rfid }) => {
    dispatch({ type: FETCH_START })
    try {
      const { token, user } = await login({ rfid })
      localStorage.setItem('kiosk-token', token)
      setAuthToken(token, logout)
      setJWTAuthData({ user, isAuthenticated: true, isLoading: false })
      dispatch({ type: FETCH_SUCCESS })
      setTimeout(() => {
        if (lastUserId === 0) {
          setLastUserId(user.id)
        } else if (lastUserId !== user.id) {
          window.location.reload()
        }
      }, 100)
    } catch (error) {
      setJWTAuthData({ ...jwtAuthData, isAuthenticated: false, isLoading: false })
      const payload = error.response?.data?.errors ? t(`server.${error.response?.data?.errors}`) : t(error.message)
      dispatch({ type: FETCH_ERROR, payload })
    }
  }

  const updateUser = user => {
    if (jwtAuthData.user) {
      setJWTAuthData({ ...jwtAuthData, user })
    }
  }

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtAuthData
      }}>
      <JWTAuthActionsContext.Provider
        value={{ signInUser, updateUser, logout }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  )
}
export default JWTAuthAuthProvider

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}
