import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'

import { StringUtils } from '../../../../utils'
import usePAFilters from './usePAFilters'

const PATable = ({ columns, data, meta, loading, onChange }) => {
  const t = StringUtils.useT()
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageSizeOptions: ['10', '25', '50', '100'],
    showSizeChanger: true,
    showTotal: (total, range) => t('app.table.pagination.total', { start: range[0], end: range[1], total })
  })

  const filteredColumns = usePAFilters(columns)

  useEffect(() => {
    if (meta) {
      setPagination({
        ...pagination,
        current: meta.page.current,
        pageSize: meta.page.perPage,
        total: meta.item.total
      })
    }
  }, [meta])

  const handlePaginationChange = newPagination => {
    const current = newPagination.pageSize === pagination.pageSize ? newPagination.current : 1
    setPagination({ ...pagination, current, pageSize: newPagination.pageSize })
    return {
      page: current,
      perPage: newPagination.pageSize
    }
  }

  const handleFilterChange = newFilters => {
    const filters = {}
    Object.keys(newFilters).forEach(field => {
      filters[field] = newFilters[field]?.length === 1 ? newFilters[field][0] : newFilters[field]
    })
    return filters
  }

  const handleSorterChange = newSorter => {
    let sorter = []
    if (Array.isArray(newSorter)) {
      sorter = newSorter.map(item => ({ field: item.field, type: item.order === 'descend' ? 'desc' : 'asc' }))
    } else if (newSorter.column) {
      sorter.push({ field: newSorter.field, type: newSorter.order === 'descend' ? 'desc' : 'asc' })
    }
    return sorter
  }

  const handleTableChange = (pagination, filters, sorter) => {
    onChange({
      pagination: handlePaginationChange(pagination),
      filters: handleFilterChange(filters),
      sorter: handleSorterChange(sorter)
    })
  }

  return (
    <Table
      columns={filteredColumns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={pagination}
      rowKey={record => record.key || record.id}
    />
  )
}

PATable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  meta: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func
}

PATable.defaultProps = {
  loading: false
}

export default PATable
