export const surat = [
  { id: 1, name: 'Al-Fatihah', n: 7 },
  { id: 2, name: 'Al-Baqarah', n: 286 },
  { id: 3, name: 'Ali \'Imran', n: 200 },
  { id: 4, name: 'An-Nisa\'', n: 176 },
  { id: 5, name: 'Al-Ma\'idah', n: 120 },
  { id: 6, name: 'Al-An\'am', n: 165 },
  { id: 7, name: 'Al-A\'raf', n: 206 },
  { id: 8, name: 'Al-Anfal', n: 75 },
  { id: 9, name: 'At-Taubah', n: 129 },
  { id: 10, name: 'Yunus', n: 109 },
  { id: 11, name: 'Hud', n: 123 },
  { id: 12, name: 'Yusuf', n: 111 },
  { id: 13, name: 'Ar-Ra\'d', n: 43 },
  { id: 14, name: 'Ibrahim', n: 52 },
  { id: 15, name: 'Al-Hijr', n: 99 },
  { id: 16, name: 'An-Nahl', n: 128 },
  { id: 17, name: 'Al-Isra\'', n: 111 },
  { id: 18, name: 'Al-Kahf', n: 110 },
  { id: 19, name: 'Maryam', n: 98 },
  { id: 20, name: 'Taha', n: 135 },
  { id: 21, name: 'Al-Anbiya\'', n: 112 },
  { id: 22, name: 'Al-Hajj', n: 78 },
  { id: 23, name: 'Al-Mu\'minun', n: 118 },
  { id: 24, name: 'An-Nur', n: 64 },
  { id: 25, name: 'Al-Furqan', n: 77 },
  { id: 26, name: 'Asy-Syu\'ara\'', n: 227 },
  { id: 27, name: 'An-Naml', n: 93 },
  { id: 28, name: 'Al-Qasas', n: 88 },
  { id: 29, name: 'Al-\'Ankabut', n: 69 },
  { id: 30, name: 'Ar-Rum', n: 60 },
  { id: 31, name: 'Luqman', n: 34 },
  { id: 32, name: 'As-Sajdah', n: 30 },
  { id: 33, name: 'Al-Ahzab', n: 73 },
  { id: 34, name: 'Saba\'', n: 54 },
  { id: 35, name: 'Fatir', n: 45 },
  { id: 36, name: 'Yasin', n: 83 },
  { id: 37, name: 'As-Saffat', n: 182 },
  { id: 38, name: 'Sad', n: 88 },
  { id: 39, name: 'Az-Zumar', n: 75 },
  { id: 40, name: 'Gafir', n: 85 },
  { id: 41, name: 'Fussilat', n: 54 },
  { id: 42, name: 'Asy-Syura', n: 53 },
  { id: 43, name: 'Az-Zukhruf', n: 89 },
  { id: 44, name: 'Ad-Dukhan', n: 59 },
  { id: 45, name: 'Al-Jasiyah', n: 37 },
  { id: 46, name: 'Al-Ahqaf', n: 35 },
  { id: 47, name: 'Muhammad', n: 38 },
  { id: 48, name: 'Al-Fath', n: 29 },
  { id: 49, name: 'Al-Hujurat', n: 18 },
  { id: 50, name: 'Qaf', n: 45 },
  { id: 51, name: 'Az-Zariyat', n: 60 },
  { id: 52, name: 'At-Tur', n: 49 },
  { id: 53, name: 'An-Najm', n: 62 },
  { id: 54, name: 'Al-Qamar', n: 55 },
  { id: 55, name: 'Ar-Rahman', n: 78 },
  { id: 56, name: 'Al-Waqi\'ah', n: 96 },
  { id: 57, name: 'Al-Hadid', n: 29 },
  { id: 58, name: 'Al-Mujadalah', n: 22 },
  { id: 59, name: 'Al-Hasyr', n: 24 },
  { id: 60, name: 'Al-Mumtahanah', n: 13 },
  { id: 61, name: 'As-Saff', n: 14 },
  { id: 62, name: 'Al-Jumu\'ah', n: 11 },
  { id: 63, name: 'Al-Munafiqun', n: 11 },
  { id: 64, name: 'At-Tagabun', n: 18 },
  { id: 65, name: 'At-Talaq', n: 12 },
  { id: 66, name: 'At-Tahrim', n: 12 },
  { id: 67, name: 'Al-Mulk', n: 30 },
  { id: 68, name: 'Al-Qalam', n: 52 },
  { id: 69, name: 'Al-Haqqah', n: 52 },
  { id: 70, name: 'Al-Ma\'arij', n: 44 },
  { id: 71, name: 'Nuh', n: 28 },
  { id: 72, name: 'Al-Jinn', n: 28 },
  { id: 73, name: 'Al-Muzzammil', n: 20 },
  { id: 74, name: 'Al-Muddassir', n: 56 },
  { id: 75, name: 'Al-Qiyamah', n: 40 },
  { id: 76, name: 'Al-Insan', n: 31 },
  { id: 77, name: 'Al-Mursalat', n: 50 },
  { id: 78, name: 'An-Naba\'', n: 40 },
  { id: 79, name: 'An-Nazi\'at', n: 46 },
  { id: 80, name: '\'Abasa', n: 42 },
  { id: 81, name: 'At-Takwir', n: 29 },
  { id: 82, name: 'Al-Infitar', n: 19 },
  { id: 83, name: 'Al-Mutaffifin', n: 36 },
  { id: 84, name: 'Al-Insyiqaq', n: 25 },
  { id: 85, name: 'Al-Buruj', n: 22 },
  { id: 86, name: 'At-Tariq', n: 17 },
  { id: 87, name: 'Al-A\'la', n: 19 },
  { id: 88, name: 'Al-Gasyiyah', n: 26 },
  { id: 89, name: 'Al-Fajr', n: 30 },
  { id: 90, name: 'Al-Balad', n: 20 },
  { id: 91, name: 'Asy-Syams', n: 15 },
  { id: 92, name: 'Al-Lail', n: 21 },
  { id: 93, name: 'Ad-Duha', n: 11 },
  { id: 94, name: 'Asy-Syarh', n: 8 },
  { id: 95, name: 'At-Tin', n: 8 },
  { id: 96, name: 'Al-\'Alaq', n: 19 },
  { id: 97, name: 'Al-Qadr', n: 5 },
  { id: 98, name: 'Al-Bayyinah', n: 8 },
  { id: 99, name: 'Az-Zalzalah', n: 8 },
  { id: 100, name: 'Al-\'Adiyat', n: 11 },
  { id: 101, name: 'Al-Qari\'ah', n: 11 },
  { id: 102, name: 'At-Takasur', n: 8 },
  { id: 103, name: 'Al-\'Asr', n: 3 },
  { id: 104, name: 'Al-Humazah', n: 9 },
  { id: 105, name: 'Al-Fil', n: 5 },
  { id: 106, name: 'Quraisy', n: 4 },
  { id: 107, name: 'Al-Ma\'un', n: 7 },
  { id: 108, name: 'Al-Kausar', n: 3 },
  { id: 109, name: 'Al-Kafirun', n: 6 },
  { id: 110, name: 'An-Nasr', n: 3 },
  { id: 111, name: 'Al-Lahab', n: 5 },
  { id: 112, name: 'Al-Ikhlas', n: 4 },
  { id: 113, name: 'Al-Falaq', n: 5 },
  { id: 114, name: 'An-Nas', n: 6 }
]
