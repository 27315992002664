import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import type { Course } from '../../models'
import { StringUtils } from '../../utils'

import './index.style.less'

const CourseCard = ({ course, progress } : { course: Course, progress: number }) => {
  const [courseBanner, setCourseBanner] = useState('/assets/images/400x300.png')
  const t = StringUtils.useT()

  const percentage = useMemo(() => {
    if (!progress || !course || course.nLectures === 0 || course.isSingleLecture) {
      return 0
    }
    return progress * 100 / course.nLectures
  }, [course, progress])

  useEffect(() => {
    setCourseBanner(course?.banner?.thumbnailUrl || '/assets/images/400x300.png')
  }, [course])

  return (
    <Link to={`/course/${course?.id}`}>
      <div title={course?.title} className='course-card-container'>
        <CircularProgressbarWithChildren
          value={percentage}
          circleRatio={0.75}
          strokeWidth={5}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,
            strokeLinecap: 'butt',
            pathColor: '#6E8870',
            trailColor: '#A9A9A9'
          })}
        >
          <div className={`progress ${course.isSingleLecture ? 'hidden' : ''}`}>
            {progress}/{course?.nLectures}
          </div>
          <div className='course-img'><img src={courseBanner} /></div>
          <div>{StringUtils.truncate(course?.title, 32)}</div>
        </CircularProgressbarWithChildren>
        <div className='course-btn'>
          <Button>{t('app.actions.select')}</Button>
        </div>
      </div>
    </Link>
  )
}

CourseCard.propTypes = {
  course: PropTypes.any,
  progress: PropTypes.number
}

export { CourseCard }
