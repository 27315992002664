import React from 'react'

const CourseList = React.lazy(() => import('./CourseList'))
const LectureDetail = React.lazy(() => import('./LectureDetail'))
const LectureList = React.lazy(() => import('./LectureList'))
const LectureSearchResult = React.lazy(() => import('./LectureSearchResult'))
const Quiz = React.lazy(() => import('./Quiz'))

export const coursePagesConfig = [
  {
    path: '/course',
    element: <CourseList />
  },
  {
    path: '/course/:id',
    element: <LectureList />
  },
  {
    path: '/course/:courseId/lecture/:id',
    element: <LectureDetail />
  },
  {
    path: '/course/:courseId/lecture/:id/quiz',
    element: <Quiz />
  },
  {
    path: '/course/search',
    element: <LectureSearchResult />
  }
]
