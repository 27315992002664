import React from 'react'
import { Button, Col, Modal, Row } from 'antd'
import PropTypes from 'prop-types'

import { useAuthMethod } from '../../../@crema/utility/AuthHooks'
import { StringUtils } from '../../../utils'

import './index.style.less'

const ExitDialog = props => {
  const { logout } = useAuthMethod()
  const t = StringUtils.useT()

  return (
    <Modal {...props} closable={false} footer={null} className='exit-dialog-container'>
      <Row>
        <Col span={24} className='hint'>{t('user.hints.logout')}</Col>
      </Row>
      <Row key={4} className='actions'>
        <Col span={12}>
          <Button onClick={logout} className='ant-btn-danger' block>{t('app.actions.exit')}</Button>
        </Col>
        <Col span={12}>
          <Button onClick={props.onCancel} className='ant-button-continue' block>{t('app.actions.continueLearning')}</Button>
        </Col>
      </Row>
    </Modal>
  )
}

ExitDialog.propTypes = {
  onCancel: PropTypes.func
}

export default ExitDialog
