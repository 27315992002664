import React from 'react'
import PropTypes from 'prop-types'

import { LectureQuran } from './LectureQuran'

import './index.style.less'

const LectureEmbedded = ({ lecture }) => {
  if (!(lecture?.content?.fileType === 'embedded')) return ''
  if (lecture?.id === 'e29ea9a3-c97e-492b-a8e9-715dbe4539bd') return <LectureQuran lecture={lecture} />

  return (
    <div className='embedded-container'>
      <iframe src={lecture.content.url} />
    </div>
  )
}

LectureEmbedded.propTypes = {
  lecture: PropTypes.any
}

export { LectureEmbedded }
