// modified from https://stackoverflow.com/a/36862446
import { useState, useEffect } from 'react'

function getWindowDimensions () {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export function useWindowDimensions () {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize () {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  function refactor (x: number, factor: number) {
    return Math.round(x * windowDimensions.width / factor)
  }

  function refactorV (x: number, factor: number) {
    return Math.round(x * windowDimensions.height / factor)
  }

  return { refactor, refactorV, ...windowDimensions }
}
