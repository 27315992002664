import React from 'react'

import './index.style.less'

const AppAllLogo = () => {
  return (
    <>
      <div className='app-all-logo'>
        <img src='/assets/images/logo.png' alt='kitabelajar-logo' />
      </div>
      <div className='app-all-partner-logo'>
        <img src='/assets/images/logo-itb.jpg' alt='logo-itb' />
        <img src='/assets/images/logo-pesantren-raden-rahmat.png' alt='logo-pesantren-raden-rahmat' />
      </div>
    </>
  )
}

export default AppAllLogo
