import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IoIosCheckmark } from 'react-icons/io'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import type { Lecture } from '../../models'
import { StringUtils } from '../../utils'

import './index.style.less'

const LectureCard = ({ lecture } : {lecture: Lecture}) => {
  const [lectureContent, setLectureContent] = useState('/assets/images/400x300.png')
  const navigate = useNavigate()
  const t = StringUtils.useT()

  useEffect(() => {
    setLectureContent(lecture?.content?.thumbnailUrl || '/assets/images/400x300.png')
  }, [lecture])

  const completed = lecture?.progress?.nCompletions > 0
  const bookmark = lecture?.progress?.bookmark
  const lectureUrl = `/course/${lecture.courseId}/lecture/${lecture.id}`

  return (
    <div className='lecture-card-container' title={lecture.title} onClick={() => navigate(lectureUrl)}>
      {completed && (
        <div className='lecture-completed'>
          <IoIosCheckmark />
        </div>
      )}
      <div className='lecture-img'>
        <img src={lectureContent} />
        {bookmark > 0 && <progress value={bookmark} />}
      </div>
      <div>{StringUtils.truncate(lecture.title, 32)}</div>
      <div className='lecture-btn'>
        <Button>{t('app.actions.select')}</Button>
      </div>
    </div>
  )
}

LectureCard.propTypes = {
  lecture: PropTypes.any
}

export { LectureCard }
