import React from 'react'

const Point = React.lazy(() => import('./Point'))
const Profile = React.lazy(() => import('./Profile'))

export const userPagesConfig = [
  {
    path: '/user/point',
    element: <Point />
  },
  {
    path: '/user/profile',
    element: <Profile />
  }
]
