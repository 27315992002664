import {
  FooterType,
  LayoutType,
  MenuStyle,
  NavStyle,
  ThemeDirection,
  ThemeMode,
  ThemeStyle
} from '../../../shared/constants/AppEnums'

export const DarkSidebar = {
  sidebarBgColor: '#32502E',
  sidebarTextColor: '#F3EFCCB8',
  sidebarHeaderColor: '#313541',
  sidebarMenuSelectedBgColor: '#F4F7FE',
  sidebarMenuSelectedTextColor: 'rgba(0, 0, 0, 0.87)',
  mode: ThemeMode.DARK
}
export const LightSidebar = {
  sidebarBgColor: '#fff',
  sidebarTextColor: 'rgba(0, 0, 0, 0.60)',
  sidebarHeaderColor: '#fff',
  sidebarMenuSelectedBgColor: '#F4F7FE',
  sidebarMenuSelectedTextColor: 'rgba(0, 0, 0, 0.87)',
  mode: ThemeMode.LIGHT
}
const defaultConfig = {
  sidebar: {
    borderColor: '#32502E',
    menuStyle: MenuStyle.ROUNDED_REVERSE,
    isSidebarBgImage: false,
    sidebarBgImage: 1,
    colorSet: DarkSidebar
  },
  locale: {
    languageId: 'indonesia',
    locale: 'id',
    name: 'Indonesia',
    icon: 'id'
  },
  themeStyle: ThemeStyle.STANDARD,
  direction: ThemeDirection.LTR,
  themeMode: ThemeMode.DARK,
  footerType: FooterType.FLUID,
  navStyle: NavStyle.DEFAULT,
  layoutType: LayoutType.FULL_WIDTH,
  footer: true,
  rtlLocale: ['ar']
}
export default defaultConfig
