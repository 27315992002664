import React from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineEye } from 'react-icons/ai'
import { BsPencil, BsTrash } from 'react-icons/bs'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'

import { StringUtils } from '../../../../utils'

const buttonMapper = {
  detail: {
    title: 'app.actions.detail',
    className: 'ant-btn-primary',
    children: <AiOutlineEye />
  },
  edit: {
    title: 'app.actions.edit',
    className: 'ant-btn-warning',
    children: <BsPencil />
  },
  delete: {
    title: 'app.actions.delete',
    className: 'ant-btn-danger',
    children: <BsTrash />
  }
}

const PAActionButtons = ({ buttons }) => {
  const t = StringUtils.useT()

  const renderButton = button => {
    if (button.displayed === false) return
    const meta = { ...buttonMapper[button.name], ...button }
    return (
      <Tooltip title={t(meta.title)} key={meta.name}>
        {meta.to && (
          <Link className={`ant-btn ant-btn-sm ${meta.className}`} to={meta.to}>
            {meta.children}
          </Link>
        )}
        {meta.action && (
          <a className={`ant-btn ant-btn-sm ${meta.className}`} onClick={meta.action}>
            {meta.children}
          </a>
        )}
        {!meta.to && !meta.action && meta.children}
      </Tooltip>
    )
  }

  return (
    <div className='actions-btn'>
      {buttons?.filter(button => !button.hidden).map(renderButton)}
    </div>
  )
}

PAActionButtons.propTypes = {
  buttons: PropTypes.array
}

PAActionButtons.defaultProps = {
  buttons: []
}

export default PAActionButtons
