import React, { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Form, Input } from 'antd'

import { StringUtils } from '../../../utils'

import './index.style.less'

const AppSearchBar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [form] = Form.useForm()
  const t = StringUtils.useT()

  const handleSubmit = ({ q }) => {
    if (location.pathname === '/course/search') {
      setSearchParams({ q })
    } else {
      navigate({ pathname: '/course/search', search: `q=${q}` })
    }
  }

  useEffect(() => {
    const q = searchParams.get('q')
    if (q) form.setFieldsValue({ q })
  }, [searchParams])

  return (
    <div className='search-bar-container'>
      <Form form={form} layout="inline" onFinish={handleSubmit}>
        <Form.Item name="q">
          <Input placeholder={t('course.hints.search')} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('course.actions.search')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AppSearchBar
