import React from 'react'

import { initialUrl } from '../shared/constants/AppConst'
import { authPagesConfig } from './auth'
import { coursePagesConfig } from './course'
import { errorPagesConfigs } from './errorPages'
import { userPagesConfig } from './user'
import Error403 from './errorPages/Error403'
import Error404 from './errorPages/Error404'

const authorizedStructure = {
  fallbackPath: '/welcome',
  unAuthorizedComponent: <Error403 />,
  routes: [...coursePagesConfig, ...userPagesConfig]
}

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authPagesConfig
}

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />
    }
  ])
}

export { authorizedStructure, unAuthorizedStructure, anonymousStructure }
