import React, { useState } from 'react'
import { FaHome, FaMoneyBill, FaSignOutAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import { ExitDialog } from '../../../../components'
import { StringUtils } from '../../../../utils'
import MainSidebar from '../components/MainSidebar'

import './index.style.less'

const AppSidebar = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const t = StringUtils.useT()

  return (
    <MainSidebar className='app-main-sidebar'>
      <div className='d-flex flex-column justify-content-between'>
        <div className='menu-group d-flex flex-column'>
          <Link to='/course' className='menu-item'>
            <FaHome />
            <span>{t('home')}</span>
          </Link>
          <Link to='/user/point' className='menu-item'>
            <FaMoneyBill />
            <span>{t('user.point.menu')}</span>
          </Link>
        </div>
        <div className='btn-logout' onClick={() => setModalVisible(true)}>
          <FaSignOutAlt />
          <span>{t('user.actions.logout.full')}</span>
        </div>
      </div>
      <ExitDialog onCancel={() => setModalVisible(false)} visible={modalVisible} />
    </MainSidebar>
  )
}

export default AppSidebar
