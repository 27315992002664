import React from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'

import AppAnimateGroup from '../AppAnimateGroup'

import './index.style.less'

const { Title } = Typography

const AppComponentHeader = ({ title, description, isHome }) => {
  return (
    <AppAnimateGroup type='top' height='auto' interval={100} duration={450}>
      <div className='container-header' key={'header'}>
        <div className='header-title'>
          <Title level={3} className={`title-h3 ${isHome ? '' : 'smaller'}`}>
            {title}
          </Title>
          {description
            ? (
            <Title level={5} className='text-base'>
              {description}
            </Title>
              )
            : null}
        </div>
      </div>
    </AppAnimateGroup>
  )
}

export default AppComponentHeader

AppComponentHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  refUrl: PropTypes.string,
  isHome: PropTypes.bool
}
AppComponentHeader.defaultProps = {
  isHome: true
}
