import { useQueryClient } from 'react-query'

import type { AnswerProgressRequest, Point, Progress, ProgressRequest } from '../../models'
import { QN_LECTURES_LIST, QN_LECTURE_DETAIL } from '../lecture'
import { QN_USER_PROGRESS } from '../user'
import { ProgressService } from './service'

export const useProgressService = () => {
  const service = new ProgressService()
  const queryClient = useQueryClient()
  let timeoutId: number

  const invalidateLectureQuery = () => {
    if (timeoutId) clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      queryClient.invalidateQueries(QN_LECTURES_LIST)
      queryClient.invalidateQueries(QN_LECTURE_DETAIL)
      queryClient.invalidateQueries(QN_USER_PROGRESS)
    }, 10000)
  }

  const store = async (progress: ProgressRequest) : Promise<Progress | undefined> => {
    const newProgress = await service.store(progress)
    invalidateLectureQuery()
    return newProgress
  }

  const storeView = async (lectureId: string) : Promise<Point | undefined> => {
    const point = await service.storeView(lectureId)
    invalidateLectureQuery()
    return point
  }

  const storeAnswer = async (progress: AnswerProgressRequest) : Promise<Point | undefined> => {
    const point = await service.storeAnswer(progress)
    invalidateLectureQuery()
    return point
  }

  const storeCompletion = async (lectureId: string) : Promise<Progress | undefined> => {
    const newProgress = await service.storeCompletion(lectureId)
    invalidateLectureQuery()
    return newProgress
  }

  const storeQuran = async (lectureId: string) : Promise<Point | undefined> => {
    const point = await service.storeQuran(lectureId)
    invalidateLectureQuery()
    return point
  }

  return { store, storeView, storeAnswer, storeCompletion, storeQuran }
}
