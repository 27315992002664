import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Select } from 'antd'
import PropTypes from 'prop-types'

import { PointDialog } from '../../components'
import { useConfig, useProgressService } from '../../services'
import { QuranUtils, StringUtils } from '../../utils'

import './index.style.less'

const LectureQuran = ({ lecture }) => {
  if (!(lecture?.id === 'e29ea9a3-c97e-492b-a8e9-715dbe4539bd')) return ''

  const [surah, setSurah] = useState(1)
  const [verse, setVerse] = useState(1)
  const [point, setPoint] = useState()
  const [dialogVisibility, setDialogVisibility] = useState(false)

  const navigate = useNavigate()
  const { data: config, isLoading } = useConfig()
  const { storeQuran } = useProgressService()
  const t = StringUtils.useT()

  const changeSurah = surah => {
    setSurah(surah)
    setVerse(1)
  }

  const surahOpts = useMemo(() => {
    return QuranUtils.surat.map(surah => ({
      label: `${surah.id.toString().padStart(3, '0')} - ${surah.name}`,
      value: surah.id
    }))
  }, [QuranUtils.surat])

  const verseOpts = useMemo(() => {
    const surahObj = QuranUtils.surat.find(s => s.id === surah)
    const n = surahObj?.n || 1
    const opts = []
    for (let i = 1; i <= n; i++) {
      opts.push({ label: i, value: i })
    }
    return opts
  }, [QuranUtils.surat, surah])

  const frameUrl = useMemo(() => {
    if (verse === 1) return `${lecture.content.url}/${surah}`
    return `${lecture.content.url}/${surah}/#no${verse}`
  }, [surah, verse])

  const sendProgress = useCallback(async () => {
    try {
      const point = await storeQuran(lecture.id)
      setPoint(point)
      setDialogVisibility(true)
    } catch (error) {}
  }, [config])

  useEffect(() => {
    if (isLoading) return false
    const timer = setTimeout(sendProgress, config.ptsQuranLimit * 1000)
    return () => clearTimeout(timer)
  }, [config, isLoading])

  return (
    <div className='embedded-container'>
      <div className='quran-nav'>
        <div>
          <label>{t('lecture.actions.selectSurah')}</label>
          <Select options={surahOpts} value={surah} onChange={changeSurah} className='surah' />
        </div>
        <div>
          <label>{t('lecture.actions.selectVerse')}</label>
          <Select options={verseOpts} value={verse} onChange={setVerse} />
        </div>
      </div>
      <iframe src={frameUrl} />
      <PointDialog
        continueLabel={t('home')}
        onCancel={() => setDialogVisibility(false)}
        onContinue={() => navigate('/course')}
        visible={dialogVisibility}
      >
        <div className='emoji mb20'>👏</div>
        <div className='text f30 mb40'>{t('lecture.hints.viewed.quran', { lecture: lecture.title })}</div>
        <div className='text f30'>{t('lecture.hints.pointsEarned.intro.alt')}</div>
        <div className='text f40 mb30'>{t('lecture.hints.pointsEarned', { n: point?.points || 0 })}</div>
      </PointDialog>
    </div>
  )
}

LectureQuran.propTypes = {
  lecture: PropTypes.any
}

export { LectureQuran }
